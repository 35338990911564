<template>
    <div>
        <p>
            <span @click="toggleCollapse" :class="folderClass">
                <font-awesome-icon :icon="folderIcon" class="mr-1"/>
                {{ dossier.nom }}
                <visibility-icon-wiki :wiki-part="dossier"/>
            </span>
            <b-button pill size="sm" class="ml-2"
                      v-b-tooltip.hover="'Télécharger'" @click="downloadZip">
                <font-awesome-icon icon="download"/>
            </b-button>
            <b-button v-if="isCluji()" pill variant="success" size="sm"
                      v-b-tooltip.hover="'Nouvel article'" @click="newArticle">
                <font-awesome-icon icon="pen-nib"/>
            </b-button>
            <span v-if="isBureau()">
                <b-button pill variant="success" size="sm"
                          v-b-tooltip.hover="'Nouveau sous-dossier'" @click="newFolder">
                    <font-awesome-icon icon="folder-plus"/>
                </b-button>
                <b-button pill variant="info" size="sm"
                          v-b-tooltip.hover="'Éditer'" @click="editFolder">
                    <font-awesome-icon icon="pen"/>
                </b-button>
                <b-button pill variant="danger" size="sm"
                          v-b-tooltip.hover="'Supprimer'" @click="confirmDeleteFolder">
                    <font-awesome-icon icon="trash-alt"/>
                </b-button>
            </span>
        </p>

        <b-collapse :id="randomString()" v-model="showCollapse">
            <dossier-wiki v-for="sousDossier in dossier.sousDossiers" :key="'dossier_' + sousDossier.id"
                          :dossier="sousDossier" class="ml-5" @reload-tree="reloadTree"/>
            <row-article-wiki v-for="article in dossier.articles" :key="'article_' + article.id"
                              :article="article" class="ml-5" @reload-tree="reloadTree"/>
        </b-collapse>
    </div>
</template>

<script>
    import {randomString}              from '@/util/text';
    import {isFolderEmpty}             from '@/util/wiki';
    import {isBureau, isCluji}         from '@/util/auth';
    import alert                       from '@/util/alert';
    import {apiPath, downloadResponse} from '@/util/http';

    const VisibilityIconWiki = () => import('./VisibilityIconWiki');
    const RowArticleWiki     = () => import('./RowArticleWiki');
    const EditFolderModal    = () => import('@/components/modals/wiki/EditFolderModal');
    const EditArticleModal   = () => import('@/components/modals/wiki/EditArticleModal');

    export default {
        name: "DossierWiki",
        components: {RowArticleWiki, VisibilityIconWiki},
        props: {
            dossier: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            showCollapse: false
        }),
        computed: {
            folderClass() {
                return isFolderEmpty(this.dossier) ? ['wiki-part-name'] : ['wiki-part-name', 'clickable-wiki-part'];
            },
            folderIcon() {
                return isFolderEmpty(this.dossier)
                    ? ['far', 'folder']
                    : this.showCollapse
                        ? 'folder-open'
                        : 'folder'
            }
        },
        methods: {
            randomString,
            isBureau,
            isCluji,
            toggleCollapse() {
                if (!isFolderEmpty(this.dossier))
                    this.showCollapse = !this.showCollapse;
            },
            newArticle() {
                this.$store.dispatch('modal/create', {
                    component: EditArticleModal,
                    props: {
                        parent: this.dossier,
                        callback: () => this.reloadTree()
                    }
                });
            },
            editFolder() {
                this.$store.dispatch('modal/create', {
                    component: EditFolderModal,
                    props: {
                        dossier: this.dossier,
                        callback: () => this.$emit('reload-tree')
                    }
                });
            },
            newFolder() {
                this.$store.dispatch('modal/create', {
                    component: EditFolderModal,
                    props: {
                        parent: this.dossier,
                        callback: () => this.reloadTree()
                    }
                });
            },
            confirmDeleteFolder() {
                if (isFolderEmpty(this.dossier)) {
                    this.deleteFolder();
                    return;
                }

                alert.confirm(
                    'Voulez-vous vraiment supprimer le dossier "' + this.dossier.nom + '" ? ' +
                    'Tout son contenu sera également supprimé ' +
                    '(sous-dossiers ET articles)',
                    () => this.deleteFolder()
                );
            },
            deleteFolder() {
                alert.loading();
                this.axios.delete(apiPath('wiki_dossier_delete', {dossier: this.dossier.id}))
                    .then(() => this.$emit('reload-tree'))
                    .catch(() => this.$toaster.error('Impossible de supprimer le dossier'))
                    .finally(alert.stopLoading);
            },
            reloadTree() {
                this.$emit('reload-tree');
                this.showCollapse = true;
            },
            downloadZip() {
                alert.loading()
                    .then(() => this.axios({
                        url: apiPath('wiki_dossier_zip', {dossier: this.dossier.id}),
                        method: 'POST',
                        responseType: 'blob'
                    }))
                    .then(downloadResponse)
                    .finally(alert.stopLoading);
            }
        }
    }
</script>
